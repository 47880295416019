var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomModal',{attrs:{"title":_vm.$t('Profile.UpdateTitle'),"submit-text":_vm.$t('Profile.Submit'),"cancel-text":_vm.$t('Profile.Cancel'),"size":"lg"},on:{"submit-modal":_vm.updateProfile,"cancel-modal":_vm.cancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"change-pwd-form"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfile)}}},[_c('div',{staticClass:"form-group row mb-0 mb-md-4"},[_c('div',{staticClass:"col-md-3 col-12 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("Profile.Email"))+" ")]),_c('div',{staticClass:"col-12 col-md-6 col-form-label text-muted"},[_vm._v(" "+_vm._s(_vm.currentUser.email)+" ")])]),_c('ValidationProvider',{attrs:{"name":"auth_full_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row mb-0 mb-md-2 required"},[_c('label',{staticClass:"col-md-3 col-12 col-form-label",attrs:{"for":"full-name"}},[_vm._v(" "+_vm._s(_vm.$t("Profile.Name"))+" ")]),_c('div',{class:['col-12 col-md-6', classes]},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.updateForm.fullName),expression:"updateForm.fullName",modifiers:{"trim":true}}],staticClass:"form-control custom-input",attrs:{"id":"full-name","placeholder":_vm.$t('Profile.Name'),"type":"text","autocomplete":"name","maxlength":_vm.nameMaxLength},domProps:{"value":(_vm.updateForm.fullName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateForm, "fullName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"auth_phone","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row mb-0 mb-md-2 required"},[_c('label',{staticClass:"col-md-3 col-12 col-form-label",attrs:{"for":"phone-number"}},[_vm._v(" "+_vm._s(_vm.$t("Profile.PhoneNumber"))+" ")]),_c('div',{class:['col-12 col-md-6', classes]},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.updateForm.phone),expression:"updateForm.phone",modifiers:{"trim":true}}],staticClass:"form-control custom-input",attrs:{"id":"phone-number","placeholder":_vm.$t('Profile.PhoneNumber'),"type":"text","autocomplete":"name","maxlength":_vm.phoneMaxLength},domProps:{"value":(_vm.updateForm.phone)},on:{"keydown":function($event){return _vm.checkValidPhone($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateForm, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)}),_c('div',{staticClass:"form-group row mb-0 mb-md-4"},[_c('div',{staticClass:"col-md-3 col-12 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("Profile.Role"))+" ")]),_c('div',{staticClass:"col-8 col-md-6 col-form-label text-muted"},[_vm._v(" "+_vm._s(_vm.currentUser.roleText)+" ")])])],1)]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }