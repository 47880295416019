<template>
	<CustomModal
		:title="$t('Profile.UpdateTitle')"
		:submit-text="$t('Profile.Submit')"
		:cancel-text="$t('Profile.Cancel')"
		size="lg"
		@submit-modal="updateProfile"
		@cancel-modal="cancel"
	>
		<template #content>
			<div class="change-pwd-form">
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(updateProfile)">
						<div class="form-group row mb-0 mb-md-4">
							<div class="col-md-3 col-12 col-form-label">
								{{ $t("Profile.Email") }}
							</div>
							<div class="col-12 col-md-6 col-form-label text-muted">
								{{ currentUser.email }}
							</div>
						</div>

						<!-- User name -->
						<ValidationProvider v-slot="{ classes, errors }" name="auth_full_name" rules="required">
							<div class="form-group row mb-0 mb-md-2 required">
								<label for="full-name" class="col-md-3 col-12 col-form-label">
									{{ $t("Profile.Name") }}
								</label>
								<div :class="['col-12 col-md-6', classes]">
									<div class="position-relative">
										<input
											id="full-name"
											v-model.trim="updateForm.fullName"
											class="form-control custom-input"
											:placeholder="$t('Profile.Name')"
											type="text"
											autocomplete="name"
											:maxlength="nameMaxLength"
										/>
									</div>
									<div class="error-mess">
										{{ errors[0] }}
									</div>
								</div>
							</div>
						</ValidationProvider>

						<!-- Phone number -->
						<ValidationProvider
							v-slot="{ classes, errors }"
							name="auth_phone"
							rules="required|phone"
						>
							<div class="form-group row mb-0 mb-md-2 required">
								<label for="phone-number" class="col-md-3 col-12 col-form-label">
									{{ $t("Profile.PhoneNumber") }}
								</label>
								<div :class="['col-12 col-md-6', classes]">
									<div class="position-relative">
										<input
											id="phone-number"
											v-model.trim="updateForm.phone"
											class="form-control custom-input"
											:placeholder="$t('Profile.PhoneNumber')"
											type="text"
											autocomplete="name"
											:maxlength="phoneMaxLength"
											@keydown="checkValidPhone($event)"
										/>
									</div>
									<div class="error-mess">
										{{ errors[0] }}
									</div>
								</div>
							</div>
						</ValidationProvider>

						<div class="form-group row mb-0 mb-md-4">
							<div class="col-md-3 col-12 col-form-label">
								{{ $t("Profile.Role") }}
							</div>
							<div class="col-8 col-md-6 col-form-label text-muted">
								{{ currentUser.roleText }}
							</div>
						</div>
					</form>
				</ValidationObserver>
			</div>
		</template>
	</CustomModal>
</template>

<script>
import { UPDATE_USER_LOGIN_PROFILE } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "UpdateProfile",
	props: {
		currentUser: {
			type: [Object, Array],
			default: null,
		},
	},
	data() {
		return {
			nameMaxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			phoneMaxLength: process.env.VUE_APP_PHONE_MAX_LENGTH,
			updateForm: {
				fullName: this.currentUser?.fullName || null,
				phone: this.currentUser?.phone || null,
			},
		}
	},
	computed: {
		...mapState({ profileErr: "profileErr" }),
	},
	watch: {
		profileErr(val) {
			if (val) this.$refs.form.setErrors(val)
		},
	},

	methods: {
		...mapActions({ UPDATE_USER_LOGIN_PROFILE }),
		updateProfile: async function () {
			const valid = await this.$refs.form.validate()
			if (!valid) return

			await this.UPDATE_USER_LOGIN_PROFILE({ ...this.updateForm })
		},
		cancel: function () {
			this.$emit("hide")
		},
	},
}
</script>
<style lang="scss" scoped>
.update-profile-form {
	margin-top: 20px;

	.form-group {
		margin-bottom: 0;
	}
}
</style>
